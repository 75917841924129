import React, { useRef, useEffect, forwardRef } from 'react'
import classNames from 'classnames'
import Head from 'next/head'
import Link from 'next/link'
import { siteTitle } from '~/constants'
import PageIllustration from '~/layout/partials/PageIllustration'
import HeroHome from '~/layout/partials/HeroHome'
import Stats from '~/layout/partials/Stats'
import Carousel from '~/layout/partials/Carousel'
import Tabs from '~/layout/partials/Tabs'
import Process from '~/layout/partials/Process'
import PricingTables from '~/layout/partials/PricingTables'
import TestimonialsBlocks from '~/layout/partials/TestimonialsBlocks'
import FeaturesBlocks from '~/layout/partials/FeaturesBlocks'
import Cta from '~/layout/partials/Cta'

import MobileImage from '~/assets/images/mobileImage.png'

const meta = {
  title: `${siteTitle} - Home page`,
}
const Comp = () => {
  console.log('siteTitle', siteTitle)
  return (
    <>
      {/* <div
        className="relative max-w-6xl mx-auto h-0 pointer-events-none -z-1"
        aria-hidden="true"
      >
        <PageIllustration />
      </div> */}
      <HeroHome
        contents={{
          title: 'A Real Estate',
          description: 'Ultimate tools for Real Estate Agents!',
          tickItems: [
            'Ultimate tools for Real Estate Agents',
            'Ultimate tools for Real Estate Agents',
            'Ultimate tools for Real Estate Agents',
            'Ultimate tools for Real Estate Agents',
            'Available on: iOS, Android and Web',
          ],
          mobileImage: MobileImage,
        }}
        options={{
          showCtaForm: false,
        }}
      />
      {/* <Stats
        sectionHeaderData={{
          title: 'Learn chinese systematically',
          description:
            'Learn chinese systematically  and throughly through the standard chinese learning level system, HSK.',
        }}
        datas={[
          { text: 'HSK 1', subText: 'beginner 150 words' },
          { text: 'HSK 2', subText: 'intermediate 300 words' },
          { text: 'HSK 3.1', subText: 'intermediate 250 words' },
          { text: 'HSK 3.2', subText: 'intermediate 250 words' },
        ]}
      /> */}
      {/* <Carousel
        sectionHeaderData={{
          title: 'Learn and Practise',
          description:
            'First learn it. Then practise it. Then move on to next chapter where you learn more which builds further upon your current learnings.',
        }}
      /> */}
      {/* <Tabs
        sectionHeaderData={{
          title: 'Learn and Practise',
          description:
            'First learn it. Then practise it. Then move on to next chapter where you learn more which builds further upon your current learnings.',
        }}
      /> */}
      {/* <Process
        sectionHeaderData={{
          title: 'Learn and Practise',
          description:
            'First learn it. Then practise it. Then move on to next chapter where you learn more which builds further upon your current learnings.',
        }}
      /> */}
      {/* <PricingTables />  */}
      {/* <TestimonialsBlocks />  */}
      {/* <FeaturesBlocks />  */}
      {/* <Cta />  */}
      {/* <View style={[tailwind(classNames('flex-1'))]}>{children}</View> */}
    </>
  )
}

Comp.meta = meta
export default Comp
